import { defineStore } from 'pinia'
import { useStorage } from '@vueuse/core'

export const useOfflineStore = defineStore('offline', {
    state: () => {
        return {
            status: useStorage('status', false),
            loading: useStorage('loading', null),
            showLoader: useStorage('showLoader', false),
            counterQueue: 0,
            workorders: [],
            addresses: [],
            workorder: useStorage('workorder', {}),
            incidence: {},
            assets: [],
            wo_assets: [],
            replies: [],
            questions: [],
            tecnicos: [],
            gamas: [],
            subgamas: [],
            operariosAdicionales: [],
            textosPredefinidos: [],
            comunicacion: {},
            modelos: [],
            sistemas: [],
            selMaterials: {all: {data: []}},
            articulo: {},
            almacenesArticulo: [],
            item_asset: {},
            lotes: [],
            proyectos: [],
            jornada_horas: {},
        }
    }
})