import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import { useGmaoStore } from '@/stores/gmao';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: () => import('@/layouts/MenuLayout.vue'),
    redirect: { name: 'login' },
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import('@/views/Home.vue')
      },

      {
        path: 'incidences',
        name: 'incidences',
        component: () => import('@/views/Incidences.vue')
      },

      {
        path: 'incidence/:id?',
        name: 'incidence',
        component: () => import('@/views/Incidence.vue')
      },

      {
        path: 'routes',
        name: 'routes',
        component: () => import('@/views/Routes.vue')
      },

      {
        path: 'workorders',
        name: 'workorders',
        component: () => import('@/views/WorkOrders.vue')
      },

      {
        path: 'not_assigned',
        name: 'not_assigned',
        component: () => import('@/views/NotAssigned.vue')
      },

      {
        path: 'workorder/:id?',
        name: 'workorder',
        component: () => import('@/views/WorkOrder.vue'),
      },

      {
        path: 'conductives/:id?',
        name: 'conductives',
        component: () => import('@/views/Conductives.vue')
      },

      {
        path: 'historicos/:id?',
        name: 'historicos',
        component: () => import('@/views/Historicos.vue')
      },

      {
        path: 'historico/:id?',
        name: 'historico',
        component: () => import('@/views/Historico.vue')
      },

      {
        path: 'done-workorders/:id?',
        name: 'done-workorders',
        component: () => import('@/views/DoneWorkOrders.vue')
      },

      {
        path: 'item/:id?/:workorder?/:sistema?',
        name: 'item',
        component: () => import('@/views/Item.vue'),
      },

      {
        path: 'documents',
        name: 'documents',
        component: () => import('@/views/Documents.vue'),
      },

      {
        path: 'calendar',
        name: 'calendar',
        component: () => import('@/views/Calendar.vue'),
      },

      {
        path: 'calendar_gantt',
        name: 'calendar_gantt',
        component: () => import('@/views/CalendarGantt.vue'),
      },

      {
        path: 'document/:id?',
        name: 'document',
        component: () => import('@/views/DocumentClient.vue'),
      },

      {
        path: 'assets_clients',
        name: 'assets_clients',
        component: () => import('@/views/AssetsClients.vue'),
      },

      {
        path: 'assets/:id',
        name: 'assets',
        component: () => import('@/views/Assets.vue'),
      },

      {
        path: 'asset/:id?/:qr?',
        name: 'asset',
        component: () => import('@/views/Asset.vue'),
      },

      {
        path: 'warehouses',
        name: 'warehouses',
        component: () => import('@/views/Warehouses.vue'),
      },
      
      {
        path: 'warehouse/:id?',
        name: 'warehouse',
        component: () => import('@/views/Warehouse.vue'),
      },
      
      {
        path: 'tools',
        name: 'tools',
        component: () => import('@/views/Tools.vue'),
      },
      
      {
        path: 'epis',
        name: 'epis',
        component: () => import('@/views/EPIs.vue'),
      },
      
      {
        path: 'tool/:id',
        name: 'tool',
        component: () => import('@/views/Tool.vue'),
      },
      
      {
        path: 'epi/:id',
        name: 'epi',
        component: () => import('@/views/EPI.vue'),
      },
      
      {
        path: 'workeddays',
        name: 'workeddays',
        component: () => import('@/views/WorkedDays.vue'),
      },

      {
        path: 'watches',
        name: 'watches',
        component: () => import('@/views/Watches.vue'),
      },

      {
        path: 'workorder-hours',
        name: 'workorder-hours',
        component: () => import('@/views/WorkOrderHours.vue'),
      },

      {
        path: 'time-recording',
        name: 'time-recording',
        component: () => import('@/views/TimeRecording.vue'),
      },

      {
        path: 'recording/:id?',
        name: 'recording',
        component: () => import('@/views/TypeRecording.vue'),
      },

      {
        path: 'absences',
        name: 'absences',
        component: () => import('@/views/Absences.vue'),
      },

      {
        path: 'absence/:id',
        name: 'absence',
        component: () => import('@/views/Absence.vue'),
      },

      {
        path: 'settings',
        name: 'settings',
        component: () => import('@/views/Settings.vue'),
      },

      {
        path: 'profile-config',
        name: 'profile-config',
        component: () => import('@/views/ProfileConfig.vue'),
      },

      {
        path: 'purchase-orders',
        name: 'purchase-orders',
        component: () => import('@/views/PurchaseOrders.vue'),
      },

      {
        path: 'order/:id?',
        name: 'order',
        component: () => import('@/views/Order.vue'),
      },

      {
        path: 'expenses',
        name: 'expenses',
        component: () => import('@/views/Expenses.vue'),
      },

      {
        path: 'expenses-item/:id?',
        name: 'expenses-item',
        component: () => import('@/views/ExpensesItem.vue'),
      },

      {
        path: 'wo-assets/:workorder?/:asset?/:sistema?',
        name: 'wo-assets',
        component: () => import('@/views/WOAssets.vue'),
      },

      {
        path: 'vehicles',
        name: 'vehicles',
        component: () => import('@/views/Vehicles.vue'),
      },

      {
        path: 'vehicle/:id',
        name: 'vehicle',
        component: () => import('@/views/Vehicle.vue'),
      },

      {
        path: 'vehicle-hours/:vehicle?',
        name: 'vehicle-hours',
        component: () => import('@/views/VehicleHours.vue'),
      },

      {
        path: 'notifications',
        name: 'notifications',
        component: () => import('@/views/Notifications.vue'),
      },

      {
        path: 'survey/:id',
        name: 'survey',
        component: () => import('@/views/Survey.vue'),
      },

      {
        path: 'test',
        name: 'test',
        component: () => import('@/views/Test.vue'),
      },
    ]
  },

  // {
  //   path: '/',
  //   component: () => import('@/layouts/TabMenuLayout.vue'),
  //   children: [
  //     {
  //       path: 'workorders/:id?',
  //       name: 'workorders',
  //       component: () => import('@/views/WorkOrders.vue')
  //     }
  //   ]
  // },

  {
    path: '/',
    component: () => import('@/layouts/BlankLayout.vue'),
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import('@/views/Login.vue')
      },
      {
        path: 'recover',
        name: 'recover',
        component: () => import('@/views/Recover.vue')
      }
    ]
  },
  {
    path: '/',
    component: () => import('@/layouts/BlankLayout.vue'),
    children: [
      {
        path: 'reset',
        name: 'reset',
        component: () => import('@/views/ResetPass.vue')
      }
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to) => {
  const gmao = useGmaoStore();
  if(('from' in gmao.timeRegisterEfective) && (to.name != 'recording' || to.params.id != gmao.timeRegisterEfective.id)) {
    if(gmao.timeRegisterEfective.id) {
      router.push({ name: 'recording', params: {id: gmao.timeRegisterEfective.id} });
    } else {
      gmao.timeRegisterEfective = { id: null };
      router.push({ name: 'workorders' });
    }
  }
});

export default router
