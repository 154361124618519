<template>
  <ion-modal
    :is-open="modelValue"
    :swipe-to-close="true"
    @didDismiss="$emit('update:modelValue', false)"
    :initialBreakpoint="0.95"
  >

    <ion-header>
      <ion-toolbar>
        <ion-title>{{ $t('Nuevo gasto') }}</ion-title>

        <ion-buttons slot="start">
          <ion-button @click="$emit('update:modelValue', false)">{{ $t('Cancelar') }}</ion-button>
        </ion-buttons>

      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding" style="height:calc(100% - 80px)">
      <ion-grid class="ion-grid-padding">
        <ion-item v-if="modulos?.modulo_proyectos && workorder?.proyecto?.id">
          <ion-label position="stacked">{{ `${$t('Proyecto')} *` }}</ion-label>
          <ion-input
            class="ion-text-right"
            :value="expenseAdd?.proyecto?.titulo"
            readonly
            clear-input
          />
        </ion-item>

        <ion-item @click="$emit('setFamilia', true)">
          <ion-label position="stacked">{{ `${$t('Familia')} *` }}</ion-label>
          <ion-input
            class="ion-text-right"
            :value="expenseAdd?.tipo?.nombre"
            readonly
            clear-input
          />
        </ion-item>

        <ion-item
          v-if="expenseAdd?.tipo?.id"
          @click="() => { $emit('setSubFamilia', {familia_id: expenseAdd?.tipo?.id || null, proyecto_id: workorder?.proyecto?.id || null}); }"
        >
          <ion-label position="stacked">{{ `${$t('SubFamilia')}` }}</ion-label>
          <ion-input
            class="ion-text-right"
            :value="expenseAdd?.subtipo?.nombre"
            readonly
            clear-input
          ></ion-input>
        </ion-item>

        <ion-row>
          <ion-col size="12">
            <ion-item>
              <ion-label position="stacked">{{ $t('Importe') }} *</ion-label>
              <ion-input type="number" inputmode="decimal" v-model="expenseAdd.price" :disabled="isInputDisabled"/>
            </ion-item>
          </ion-col>
          <ion-col size="12">
            <ion-item>
              <ion-label position="stacked">{{
                $t('Descripción')
              }}</ion-label>
              <ion-textarea v-model="expenseAdd.description" />
            </ion-item>
          </ion-col>
          <ion-col size="12">
            <ion-item>
              <ion-label>{{ $t('Imagen') }} <span v-if="comportamientos.foto_obligatoria_gastos">*</span></ion-label>
              <ion-button
                class="header-icon ion-no-margin"
                size="small"
                fill="clear"
                color="dark"
                @click="addPhotoExpense"
              >
                <ion-icon :icon="camera"></ion-icon>
              </ion-button>
            </ion-item>
          </ion-col>
          <ion-col size="12" v-if="!+comportamientos.gastos_oculta_pagado">
            <ion-item>
              <ion-label>{{ $t('Pagado') }}</ion-label>
              <ion-toggle value="paid" v-model="expenseAdd.paid">
              </ion-toggle>
            </ion-item>
          </ion-col>
          <ion-col size="12" v-if="comportamientos.texto_aviso_gasto_nuevo">
              <ion-item>
                <ion-label color="danger" style="text-align: center;">{{ comportamientos.texto_aviso_gasto_nuevo }}</ion-label>
              </ion-item>
            </ion-col>
        </ion-row>

        <ion-button
          expand="full"
          @click="setExpense(expenseAdd)"
          :disabled="disabledButton"
        >
          {{ $t('Añadir gasto') }}
        </ion-button>
      </ion-grid>
    </ion-content>
  </ion-modal>
</template>

<script>
import { ref, getCurrentInstance } from 'vue';

// Stores
import { useGmaoStore } from '@/stores/gmao';

// EXPORT MODULE
// import { SQLiteDBConnection } from 'vue-sqlite-hook/dist';
import { useOfflineStore } from '@/stores/offline';

import { camera } from 'ionicons/icons';

import {
  IonModal,
  IonButton,
  IonLabel,
  IonItem,
  IonContent,
  IonTextarea,
  IonInput,
  IonGrid,
  IonRow,
  IonCol,
  IonToggle,
} from '@ionic/vue';

export default {
  name: 'MSetAddExpense',
  isInputDisabled: ref(false),

  props: {
    modelValue: {
      required: true,
      type: Boolean,
    },

    workorder: {
      required: true,
      type: Object,
    },

    modulos: {
      type: Object,
      required: true,
    },

    comportamientos: {
      type: Object,
      required: true,
    },
  },

  components: {
    IonModal,
    IonButton,
    IonLabel,
    IonItem,
    IonContent,
    IonTextarea,
    IonInput,
    IonGrid,
    IonRow,
    IonCol,
    IonToggle,
  },

  watch: {
    modelValue(val) {
      if (val) {
        if (this.workorder.proyecto?.id) this.expenseAdd.proyecto = this.workorder.proyecto;
      } else {
        this.expenseAdd = {};
      }
    },
    'expenseAdd.tipo': {
      handler(newValue) {
        if (newValue?.valor_defecto) {
          this.expenseAdd.price = newValue?.valor_defecto;
          this.isInputDisabled = true;
        } else {
          this.expenseAdd.price = 0;
          this.isInputDisabled = false;
        }
        if (this.workorder.proyecto?.id) this.expenseAdd.proyecto = this.workorder.proyecto;
      },
      deep:true
    }
  },

  computed: {
    disabledButton() {
      return +this.comportamientos.foto_obligatoria_gastos && !this.expenseAdd.blob;
    },
  },

  setup() {
    const gmao = useGmaoStore();
    const offline = useOfflineStore();

    const app = getCurrentInstance();

    return {
      expenseAdd: ref({}),

      // Stores
      gmao,
      offline,

      // OTHER
      app,
      camera,
    };
  },

  methods: {
    // POST
    setExpense(exp) {
      if (!this.expenseAdd.tipo?.id || !this.expenseAdd.price || !this.expenseAdd.price > 0) {
        this.app.appContext.config.globalProperties.$openToastObject(
          this.$t('Introduce los campos obligatorios'), 
          this.$t('Tiene que introducir los campos obligatorios para poder imputar un gasto.'),
          'danger'
        );
      } else if (+this.gmao.comportamientos.proyecto_relaciona_gasto_familia && !this.expenseAdd.proyecto?.id) {
        this.app.appContext.config.globalProperties.$openToastObject(
          this.$t('Introduce los campos obligatorios'),
          this.$t('Tiene que seleccionar proyecto para poder imputar un gasto.'),
          'danger'
        );
      } else {
        const formdata = new FormData();
        formdata.append('file', exp.blob);
        formdata.append('u', this.gmao.user.id);
        formdata.append('tipo', this.expenseAdd.tipo?.id || '');
        formdata.append('subtipo', this.expenseAdd.subtipo?.id || '');
        formdata.append('id_parte', this.workorder?.id || this.$route.params?.id || '');
        formdata.append('proyecto', this.expenseAdd.proyecto?.id || '');
        formdata.append('price', this.expenseAdd.price || '');
        formdata.append('description', this.expenseAdd.description || '');
        formdata.append('paid', this.expenseAdd.paid || false);
        this.$axios
          .post(
            `/v2/users/actions.php?call=setExpense&token=${this.gmao.user.token}&v2=1`,
            formdata,
            { headers: { 'Content-Type': 'multipart/form-data' } }
          )
          .then(({ data }) => {

            if (!+data.status && !data.gasto?.id) {
              this.app.appContext.config.globalProperties.$openToastObject(this.$t('Ha ocurrido un error'), data.mns || this.$t('No se ha podido añadir el gasto.'), 'danger');
            } else {
              this.$emit('update:modelValue', false)
            }
            this.$emit('done', null);
          }).catch((error) => {
          // TODO: Offline
          console.log(error);
        });
      }
    },

    // Other
    async addPhotoExpense() {
      const photo = await this.$Camera.getPhoto({
        resultType: this.$CameraResultType.Base64,
        source: this.$CameraSource.Camera,
        promptLabelPhoto: this.$t('gallery-photo'),
        promptLabelPicture: this.$t('camera-photo'),
        quality: 100,
        saveToGallery: true,
      });
      if (photo) {
        const blob = this.$base64toBlob(photo.base64String);
        this.expenseAdd.blob = blob;
        this.expenseAdd.image = `data:image/png;base64,${photo.base64String}`;
      } else {
        this.app.appContext.config.globalProperties.$openToastObject(
            'Error',
            'La imagen no ha podido guardarse.',
            'danger'
          );
      }
    }
  },
};
</script>