<template>
  <ion-item class="title" lines="none">
    <h5>{{ $t('DATOS') }}</h5>
    <div class="header-icon" style="
        color: inherit;
        display: flex;
        align-items: center;
        margin-right: 8px;
      ">
      <ion-text v-if="isComingFromWO && asset.tiempos_sum_tiempo_total"
        style="font-size: 10px; margin-right: 8px">
        {{
        this.$timeFormatter(asset.tiempos_sum_tiempo_total || 0).hours
        }}h
        {{
        this.$timeFormatter(asset.tiempos_sum_tiempo_total || 0).minutes
        }}m
        {{
        this.$timeFormatter(asset.tiempos_sum_tiempo_total || 0).seconds
        }}s
      </ion-text>
    </div>
  </ion-item>

  <ion-card
    :style="`${modelValue ? 'box-shadow: none; border-radius: 15px; border: 1.5px solid var(--ion-color-tertiary-alpha)' : 'box-shadow: none; border-radius: 15px'}`">
    <!-- <div class="hr" style="margin: 0;" /> -->
    <ion-card-content class="ion-no-padding">
      <ion-grid>
        <ion-row>
          <ion-col size="6" v-if="asset.modelo?.modelo"
            @click="() => $emit('setModelo', modelValue)">
            <ion-item class="wo-item" lines="none">
              <ion-label>
                <ion-text>
                  <h3>{{ $t('Modelo') }}</h3>
                </ion-text>
                <p v-if="!modelValue">{{ asset.modelo?.modelo }}</p>
                <p v-else>{{ activeDeepCopy.modelo?.modelo }}</p>
              </ion-label>
              <ion-icon v-if="modelValue"
                style="font-size: 20px; color: var(--ion-color-tertiary-alpha)"
                :icon="pencilOutline" />
            </ion-item>
          </ion-col>

          <ion-col size="6" v-if="asset.modelo?.marca"
            @click="() => $emit('setModelo', modelValue)">
            <ion-item class="wo-item" lines="none">
              <ion-label>
                <ion-text>
                  <h3>{{ $t('Marca') }}</h3>
                </ion-text>
                <p v-if="!modelValue">{{ asset.modelo?.marca }}</p>
                <p v-else>{{ activeDeepCopy.modelo?.marca }}</p>
              </ion-label>
              <ion-icon v-if="modelValue"
                style="font-size: 20px; color: var(--ion-color-tertiary-alpha)"
                :icon="pencilOutline" />
            </ion-item>
          </ion-col>

          <ion-col size="6" v-if="asset.numeroserie">
            <ion-item class="wo-item" lines="none">
              <ion-label>
                <ion-text>
                  <h3>{{ $t('Núm. Serie') }}</h3>
                </ion-text>

                <p v-if="!modelValue">{{ asset.numeroserie }}</p>
                <ion-textarea v-if="modelValue" :value="activeDeepCopy.numeroserie" :autoGrow="true"
                  class="editableField" @ionChange="({ target }) => $emit('mutateAsset', {
                    field: 'numeroserie',
                    value: target.value
                  })" />
              </ion-label>
            </ion-item>
          </ion-col>

          <ion-col size="6" v-if="asset.fecha_instalacion">
            <ion-item class="wo-item" lines="none">
              <ion-label>
                <ion-text>
                  <h3>{{ $t('Instalación') }}</h3>
                </ion-text>

                <p v-if="!modelValue">{{ $moment(asset.fecha_instalacion).format('L') }}</p>

                <ion-item class="assetDate" lines="none" v-if="modelValue">
                  <ion-input class="editableField" id="open-date-instalacion"
                    :value="activeDeepCopy.fecha_instalacion ? $moment(activeDeepCopy.fecha_instalacion).format('L') : null"
                    readonly />
                  <!-- <ion-icon :icon="closeOutline" slot="end" @click="activeDeepCopy.fecha_instalacion = null"></ion-icon> -->
                  <ion-modal ref="fechaDesde" class="date-modal" trigger="open-date-instalacion">
                    <ion-content force-overscroll="false">
                      <ion-datetime @ionChange="($event) => {
                          $emit('mutateAsset', {
                            field: 'fecha_instalacion',
                            value: $event?.detail?.value || activeDeepCopy.fecha_instalacion
                          });
                          $refs.fechaDesde.$el.dismiss();
                        }"
                        :value="$moment(activeDeepCopy.fecha_instalacion, 'DD-MM-YYYY').format('YYYY-MM-DD')"
                        presentation="date" firstDayOfWeek=1 />
                    </ion-content>
                  </ion-modal>
                </ion-item>

                <!-- <ion-textarea
                  v-if="modelValue"
                  v-model="activeDeepCopy.fecha_instalacion"
                  :autoGrow="true"
                  class="editableField"
                /> -->
              </ion-label>
            </ion-item>
          </ion-col>

          <ion-col size="6" v-if="asset.fingarantia">
            <ion-item class="wo-item" lines="none">
              <ion-label>
                <ion-text>
                  <h3>{{ $t('Garantía') }}</h3>
                </ion-text>

                <p v-if="!modelValue">{{ asset.fingarantia }}</p>

                <ion-item class="assetDate" lines="none" v-if="modelValue">
                  <ion-input class="editableField" id="open-date-garantia"
                    :value="activeDeepCopy.fingarantia ? $moment(activeDeepCopy.fingarantia).format('L') : null"
                    readonly />
                  <!-- <ion-icon :icon="closeOutline" slot="end" @click="activeDeepCopy.fingarantia = null"></ion-icon> -->
                  <ion-modal ref="fechaGarantia" class="date-modal" trigger="open-date-garantia">
                    <ion-content force-overscroll="false">
                      <ion-datetime @ionChange="($event) => {
                          $emit('mutateAsset', {
                            field: 'fingarantia',
                            value: $event?.detail?.value || activeDeepCopy.fingarantia
                          });
                          $refs.fechaGarantia.$el.dismiss();
                        }"
                        :value="$moment(activeDeepCopy.fingarantia, 'DD-MM-YYYY').format('YYYY-MM-DD') "
                        presentation="date" firstDayOfWeek=1 />
                    </ion-content>
                  </ion-modal>
                </ion-item>

                <!-- <ion-textarea
                  v-if="modelValue"
                  v-model="activeDeepCopy.fingarantia"
                  :autoGrow="true"
                  class="editableField"
                /> -->
              </ion-label>
            </ion-item>
          </ion-col>

          <ion-col size="6" v-if="asset.ubicacion">
            <ion-item class="wo-item" lines="none">
              <ion-label>
                <ion-text>
                  <h3>{{ $t('Ubicacion') }}</h3>
                </ion-text>

                <p v-if="!modelValue" class="ion-text-wrap">{{ asset.ubicacion }}</p>
                <ion-textarea v-if="modelValue" :value="activeDeepCopy.ubicacion" @ionChange="({ target }) => $emit('mutateAsset', {
                    field: 'ubicacion',
                    value: target.value
                  })" :autoGrow="true" class="editableField" />
              </ion-label>
            </ion-item>
          </ion-col>

          <ion-col size="6" v-if="asset.observaciones">
            <ion-item class="wo-item" lines="none">
              <ion-label>
                <ion-text>
                  <h3>{{ $t('Observaciones') }}</h3>
                </ion-text>

                <p v-if="!modelValue" class="ion-text-wrap">{{ asset.observaciones }}</p>
                <!-- <ion-input v-if="modelValue" v-model="activeDeepCopy.observaciones" class="editableField" /> -->
                <ion-textarea v-if="modelValue" :value="activeDeepCopy.observaciones" @ionChange="({ target }) => $emit('mutateAsset', {
                    field: 'observaciones',
                    value: target.value
                  })" :autoGrow="true" class="editableField" />
              </ion-label>
            </ion-item>
          </ion-col>

          <template v-if="asset?.campos?.length">
            <ion-col v-for="(campo, i) in asset.campos" :key="`campo-${campo.id}`" size="6">

              <ion-item class="wo-item" lines="none">
                <ion-label>
                  <ion-text>
                    <h3>{{ campo.campo.nombre }}</h3>
                  </ion-text>

                  <p v-if="!modelValue" class="ion-text-wrap">{{ campo.descripcion }}</p>
                  <ion-textarea v-if="modelValue" :value="activeDeepCopy.campos[i]?.descripcion"
                    @ionChange="({ target }) => $emit('mutateAsset', {
                      field: `campo-${campo.id}`,
                      value: target.value,
                    })" :autoGrow="true" class="editableField" />
                </ion-label>
              </ion-item>

            </ion-col>
          </template>

        </ion-row>
      </ion-grid>
    </ion-card-content>
    <ion-buttons v-if="modelValue" class="flex ion-justify-content-end">
      <ion-button @click="dismissAssetEdit" :disabled="active?.not_editable">
        <ion-icon class="f20" style="color:var(--ion-color-danger);" :icon="closeCircleOutline" />
      </ion-button>
      <ion-button @click="setAssetEdit" :disabled="active?.not_editable">
        <ion-icon class="f20" style="color:var(--ion-color-success);"
          :icon="checkmarkCircleOutline" />
      </ion-button>
    </ion-buttons>
  </ion-card>
</template>

<script>
import {
  IonContent,
  IonButton,
  IonButtons,
  IonDatetime,
  IonModal,
  IonIcon,
  IonItem,
  IonInput,
  IonLabel,
  IonTextarea,
} from '@ionic/vue';

import {
  chevronBack,
  stopwatchOutline,
  closeOutline,
  createOutline,
  imagesOutline,
  camera,
  add,
  closeCircle,
  closeCircleOutline,
  checkmarkCircleOutline,
  pencilOutline,
} from 'ionicons/icons';

import { useGmaoStore } from '@/stores/gmao';

export default {
  name: 'AssetData',
  props: {
    asset: {
      type: Object,
      required: true
    },

    activeDeepCopy: {
      type: Object,
      required: true
    },

    isComingFromWO: {
      type: Boolean,
      required: false,
      default: true,
    },

    modelValue: {
      type: Boolean,
      required: true
    }
  },

  components: {
    IonContent,
    IonModal,
    IonLabel,
    IonButton,
    IonItem,
    IonDatetime,
    IonIcon,
    IonButtons,
    IonInput,
    IonTextarea,
  },

  setup() {

    const gmao = useGmaoStore();

    return {
      // ICONS
      chevronBack,
      stopwatchOutline,
      closeOutline,
      imagesOutline,
      camera,
      add,
      closeCircle,
      pencilOutline,
      close,
      createOutline,
      closeCircleOutline,
      checkmarkCircleOutline,

      // STORES
      gmao,
    };
  },

  methods: {

    dismissAssetEdit() {
      this.$emit('update:modelValue', false);
    },

    setAssetEdit() {
      this.$emit('setAsset', this.activeDeepCopy)
    },

  }
}
</script>

<style lang="scss" scoped>
.removeBtn {
  position: absolute;
  top: 0.5rem;
  right: 0.25rem;
}
.wo-item {
  // border: 1px solid #dadada;
  height: 100%;

  // & ion-text { margin-bottom: 20px; }
  & p {
    font-size: 12px;
  }
}
ion-content {
  --ion-background-color: #f6f6f6;
}

ion-card {
  --background: white !important;
}
.title {
  text-transform: capitalize;
  --ion-background-color: transparent;
  margin-bottom: -15px !important;
}

ion-item {
  --ion-background-color: white;
}

ion-list {
  --ion-background-color: white;
}
h5 {
  font-size: 10pt !important;
  color: #767676;
}
item-inner {
  border-style: none !important;
}

.date-modal {
  background-color: rgba(0, 0, 0, 0.4);
  --width: 290px;
  --height: 382px;
  --border-radius: 8px;
}

.date-modal ion-datetime {
  height: 382px;
  width: 100%;
}

.editableField {
  background-color: var(--ion-color-tertiary-alpha);
  border-radius: 10px;
  font-size: 12px;
  padding-left: 5px !important;
}

.assetDate::part(native) {
  padding-left: 0;
}


.priority-1 {
  border-left: 15px solid var(--priority-1);
  & > ion-icon {
    color: var(--priority-1);
  }
}
.priority-2 {
  border-left: 15px solid var(--priority-2);
  & > ion-icon {
    color: var(--priority-2);
  }
}
.priority-3 {
  border-left: 15px solid var(--priority-3);
  & > ion-icon {
    color: var(--priority-3);
  }
}
.priority-4 {
  border-left: 15px solid var(--priority-4);
  & > ion-icon {
    color: var(--priority-4);
  }
}
.priority-5 {
  border-left: 15px solid var(--priority-5);
  & > ion-icon {
    color: var(--priority-5);
  }
}

.active-finished::part(native) {
  background-color: #00acac20;
  min-height: 70px;
}

.checkboxes .ion-color {
  --border-color: var(--ion-color-tertiary);
}

.activosOT {
  min-height: 70px;
  margin-bottom: 1px;

  display: flex;
  flex-direction: row;
  flex-shrink: inherit;
  width: 100%;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  &:last-child {
    margin-bottom: 0;
  }
}
.overflowText > h3 {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 90%;
}
.datatime {
  background: var(--ion-color-tertiary-alpha);
}

ion-searchbar.defaultSearchBar {
  --background: #fff;
  --border-radius: 1rem;
}

</style>