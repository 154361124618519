<template>
  <ion-modal
    :is-open="modelValue"
    @didDismiss="$emit('update:modelValue', false)"
    :initialBreakpoint="0.95"
  >
    <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-button @click="$emit('update:modelValue', false)">{{ $t('Cancelar') }}</ion-button>
          </ion-buttons>

          <ion-title>{{ $t('Duplicar parte') }}</ion-title>

          <ion-buttons slot="end">
            <ion-button @click="setDuplicateWorkorder">{{ $t('Duplicar') }}</ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>

    <ion-content class="ion-padding" style="height:calc(100% - 80px)">
      <ion-item>
        <ion-label position="floating">{{ $t('Descripcion') }}</ion-label>
        <ion-textarea v-model="duplicatedWorkorder.problema"></ion-textarea>
      </ion-item>

      <ion-datetime style="max-width:100%" presentation="date" v-model="duplicatedWorkorder.fecha"></ion-datetime>
    </ion-content>
  </ion-modal>
</template>

<script>
import { ref, getCurrentInstance } from 'vue';

// Stores
import { useGmaoStore } from '@/stores/gmao';

// EXPORT MODULE
import { SQLiteDBConnection } from 'vue-sqlite-hook/dist';
import { useOfflineStore } from '@/stores/offline';

import {
  IonModal,
  IonButton,
  IonToolbar,
  IonHeader,
  IonTitle,
  IonButtons,
  IonLabel,
  IonItem,
  IonContent,
  IonTextarea,
  IonDatetime,
} from '@ionic/vue';

export default {
  name: 'MSetDuplicateWorkorder',
  props: {
    modelValue: {
      required: true,
      type: Boolean,
    },

    maquinas: {
      required: true,
      type: Array,
    },

    workorder: {
      required: true,
      type: Object,
    },
  },

  components: {
    IonModal,
    IonButton,
    IonToolbar,
    IonHeader,
    IonTitle,
    IonButtons,
    IonLabel,
    IonItem,
    IonContent,
    IonTextarea,
    IonDatetime,
  },

  watch: {
    modelValue(value) {
      if (value) {
        this.duplicatedWorkorder.problema = this.workorder.problema;
      }
    }
  },

  computed: {},

  setup() {
    const gmao = useGmaoStore();
    const offline = useOfflineStore();

    const app = getCurrentInstance();

    return {
      duplicatedWorkorder: ref({}),

      // Stores
      gmao,
      offline,

      // OTHER
      app,
    };
  },

  methods: {
    setDuplicateWorkorder() {
      const not_finished = this.maquinas.map((a) => a.id);

      return this.$axios
        .post(
          `/v2/users/actions.php?call=setDuplicateWorkorder&token=${this.gmao.user.token}&v2=1`,
          {
            data: {
              workorder: this.workorder.id,
              tecnico: this.gmao.user.id,
              problema: this.duplicatedWorkorder.problema,
              fecha: this.duplicatedWorkorder.fecha,
              activos: not_finished,
            }
          },
          {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          }
        )
        .then(() => {
          this.$emit('update:modelValue', false);

          this.app.appContext.config.globalProperties.$openToastObject(
            'Parte duplicado',
            'El parte se ha duplicado correctamente',
            'success'
          );

          this.$router.go(-1);
        })
        .catch(async (e) => {
          if (e.code == 'ERR_NETWORK' && this.offline.status) {
            try {
              const sqlite = this.app?.appContext.config.globalProperties.$sqlite;

              const ret = await sqlite.checkConnectionsConsistency();
              const isConn = (await sqlite.isConnection('gmaoTecnicos', false)).result;

              let db = new SQLiteDBConnection();
              if (ret.result && isConn) {
                db = await sqlite.retrieveConnection('gmaoTecnicos', false);
              } else {
                db = await sqlite.createConnection(
                  'gmaoTecnicos',
                  false,
                  'no-encryption',
                  1,
                  false
                );
              }
              await db.open();

              const newO = {};
              Object.keys(this.workorder).forEach((k, i) => {
                let value = Object.values(this.workorder)[i];
                if (
                  (typeof value == 'number' ||
                    typeof value == 'string' ||
                    typeof value != 'object') &&
                  value != null
                ) {
                  if (typeof value == 'string') {
                    value = `'${value}'`;
                  }
                  newO[k] = value;
                }
              });

              const syncObject = {
                callFunction: 'setDuplicateWorkorder',
                data: {
                  workorder: this.workorder.id,
                  tecnico: this.gmao.user.id,
                  problema: this.duplicatedWorkorder.problema,
                  fecha: this.duplicatedWorkorder.fecha,
                  activos: not_finished,
                },
              };

              const tempID = this.$moment().unix();

              newO.id = tempID;
              newO.syncObject = `'${JSON.stringify(syncObject)}'`;
              newO.id_estado_actual = 3;
              newO.offline = `'#FF0000'`;
              newO.problema = `'${this.duplicatedWorkorder.problema}'`;
              newO.fecha = `'${this.$moment().format('YYYY-MM-DD')}'`;
              newO.created_at = `'${this.$moment().format('YYYY-MM-DD H:mm:ss')}'`;
              newO.updated_at = `'${this.$moment().format('YYYY-MM-DD H:mm:ss')}'`;
              newO.last_modified = `'${this.$moment().format('YYYY-MM-DD H:mm:ss')}'`;

              const query = `
                INSERT INTO partes
                  (${Object.keys(newO).toString()})
                VALUES
                (${Object.values(newO).toString()});
              `;

              const anteriorParte = {
                id_antiguo_parte: this.workorder.id,
                id_parte_actual: tempID,
                tipo_letra: this.workorder.tipo_letra,
                comportamiento_duplicar_preventivos_activos: +this.gmao.comportamientos
                  .duplicar_no_finalizados_preventivos_activos,
                comportamiento_duplicar_correctivos_activos: +this.gmao.comportamientos
                  .duplicar_no_finalizados_activos,
              };

              const setDuplicateWorkorderPromise = new Promise((resolve) => {
                resolve(
                  this.$SQLiteQuery(
                    'setDuplicateWorkorder_workorder',
                    'partes',
                    [query, anteriorParte],
                    e.config,
                    db
                  )
                );
              });
              setDuplicateWorkorderPromise.then(() => {
                setTimeout(() => {
                  // this.setActivos = false;
                  sqlite.closeConnection('gmaoTecnicos').then(async () => {
                    await this.getWorkorder(this.$route.params.id).then(async () => {
                      this.duplicateWorkorder = false;
                      this.$emit('update:modelValue', false);
                      this.app.appContext.config.globalProperties.$openToastObject(
                        'Parte duplicado',
                        'El parte se ha duplicado correctamente',
                        'success'
                      );

                      this.$router.go(-1);
                    });
                  });
                }, 500);
              });
            } catch (err) {
              // this.$Sentry.withScope(function(scope) {

              //   scope.setTag("offline", "Workorder: SET WO Duplicado");

              //   scope.setFingerprint([err.name, err.message, String(err.stack)]);

              // });
                this.$Sentry.captureException(err);
            }
          } else {
            this.app.appContext.config.globalProperties.$openToastObject(
              this.$t('error-al-subir-la-firma'),
              this.$t(
                'el-servidor-no-ha-podido-procesar-la-subida-de-imagen-por-favor-intentalo-mas-tarde'
              )
            );
          }
        });
    },
  },
};
</script>