<template>
  <ion-modal :is-open="modelValue" @didDismiss="$emit('update:modelValue', false)"
    :initialBreakpoint="0.95">
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="$emit('update:modelValue', false)">{{ $t('Cancelar') }}</ion-button>
        </ion-buttons>

        <ion-title>{{
          !modalHour.id
          ? $t('Añadir tiempo')
          : $t('Editar tiempo')
          }}</ion-title>

        <ion-buttons slot="end">
          <ion-button v-if="+comportamientos?.campo_tipo_tiempo_obligatorio" :disabled="!hayTipo"
            @click="() => timeValidator()">{{
            !modalHour.id ? $t('Añadir') : $t('Guardar')
            }}</ion-button>
          <ion-button v-else @click="() => timeValidator()">{{
            !modalHour.id ? $t('Añadir') : $t('Guardar')
            }}</ion-button>
        </ion-buttons>

      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding" style="height:calc(100% - 80px)">
      <ion-list>
        <ion-item v-if="isTecnicosAdicionales && isMainTechnician" @click="$emit('tecnicosModal', true)">
          <ion-label>{{ $t('Técnicos') }}</ion-label>
          <ion-input class="ion-text-right" :value="techName" readonly clear-input></ion-input>
        </ion-item>

        <ion-item>
          <ion-label>{{ $t('Fecha Inicio') }}</ion-label>

          <ion-datetime-button datetime="imputacionDatetime"></ion-datetime-button>

          <ion-modal class="date-modal" :keep-contents-mounted="true">
            <ion-datetime v-model="modalHour.date" style="color: black" id="imputacionDatetime"
              presentation="date" :max="moment().format('YYYY-MM-DD')"></ion-datetime>
          </ion-modal>
        </ion-item>

        <ion-item>
          <ion-label>{{ $t('Fecha Fin') }}</ion-label>

          <ion-datetime-button datetime="imputacionDatetime2"></ion-datetime-button>

          <ion-modal class="date-modal" :keep-contents-mounted="true">
            <ion-datetime v-model="modalHour.date_fin" style="color: black" id="imputacionDatetime2"
              presentation="date" :max="moment().format('YYYY-MM-DD')"></ion-datetime>
          </ion-modal>
        </ion-item>

        <ion-item>
          <ion-label id="open-modal">{{ $t('Desde') }}</ion-label>
          <ion-input class="ion-text-right" v-model="modalHour.from" clear-input type="time">
          </ion-input>
        </ion-item>

        <ion-item>
          <ion-label>{{ $t('Hasta') }}</ion-label>
          <ion-input class="ion-text-right" v-model="modalHour.to" clear-input type="time">
          </ion-input>
        </ion-item>

        <ion-item>
          <ion-input
            field-type="type"
            :label="$t('Tipo tiempo')"
            @click="$emit('setTipoTiempos', null)"
            :value="!modalHour.id ? modalHour.nombreTipoTiempo : modalHour.hora?.tipo_tiempo?.nombre"
            readonly
            clear-input
          />
        </ion-item>

        <ion-item v-if="comportamientos?.usar_partidas_en_obras && hasPartidas"
          @click="$emit('partidasModal', null)">
          <ion-label>{{ $t('Partida presupuestaria') }}</ion-label>
          <ion-input class="ion-text-right" :value="
                modalHour.partida
                  ? `${modalHour.partida.codigo} - ${modalHour.partida.descripcion}`
                  : ''
              " readonly clear-input></ion-input>
        </ion-item>

        <ion-item>
          <ion-label position="floating">{{ $t('Observaciones') }}</ion-label>
          <ion-textarea v-model="modalHour.observaciones"></ion-textarea>
        </ion-item>
      </ion-list>

      <template v-if="showDeleteAction">
        <div class="delete-box">
          <ion-button size="small" style="display:flex;width:80%;margin-top: 3rem;" color="danger" @click="resolveAction('delete', modalHour)">
            {{ $t('Eliminar') }}
          </ion-button>
        </div>
      </template>
    </ion-content>

  </ion-modal>
</template>

<script>
import { ref } from 'vue';
import moment from 'moment';

import {
  IonModal,
  IonButton,
  IonList,
  IonToolbar,
  IonHeader,
  IonTitle,
  IonButtons,
  IonLabel,
  IonItem,
  IonContent,
  IonInput,
  IonTextarea,
  IonDatetime,
  IonDatetimeButton,
} from '@ionic/vue';

export default {
  name: 'MSetAddHoras',
  props: {
    modelValue: {
      required: true,
      type: Boolean,
    },

    newHora: {
      required: true,
      type: Object,
    },

    comportamientos: {
      required: false,
      type: Object,
    },

    techName: {
      required: true,
      type: String,
    },

    isTecnicosAdicionales: {
      required: true,
      type: Boolean,
    },

    isMainTechnician: {
      required: true,
      type: Boolean,
    },

    hasPartidas: {
      required: true,
      type: Boolean,
    },

    hayTipo: {
      required: true,
      type: Boolean,
    },

    deleteItemCallback: {
      type: Function,
      default: () => { }
    }
  },

  components: {
    IonModal,
    IonButton,
    IonList,
    IonToolbar,
    IonHeader,
    IonTitle,
    IonButtons,
    IonLabel,
    IonItem,
    IonContent,
    IonInput,
    IonTextarea,
    IonDatetime,
    IonDatetimeButton,
  },

  setup() {
    return {
      moment,


      validTime: ref(false),
      localComponentTime: ref({}),
      modalHour: ref({}),
    };
  },

  watch: {
    modelValue: function (newValue) {
      if (newValue) {
        this.modalHour = { ...this.newHora };
      }
    },

    'newHora.tecnico': function() {
      this.modalHour = { ...this.newHora };
    }
  },

  computed: {
    showDeleteAction() {
      return this.modalHour?.id && this.$hasPermissions('horas', 'borrar');
    }
  },

  methods: {
    resolveAction(action = '', hour) {

      if (action === 'delete') {
        this.$showDeleteAlertController(
          undefined,
          undefined,
          () => this.deleteItemCallback(hour),
        );
      } else {
        this.$openToastObject(
          this.$t('Ha ocurrido un error'),
          this.$t('generic-delete-error-message')
        );
      }
    },

    // XXX: Tenemos variable global en main.ts -> this.$isTimeValid
    timeValidator() {
      const startTime = this.modalHour.from;
      const endTime = this.modalHour.to;

      const startDateTime = this.$moment(this.modalHour?.date);
      const endDateTime = this.$moment(this.modalHour?.date_fin);

      const isEndBeforeStartHour = this.$moment(endTime, 'hh:mm').isBefore(this.$moment(startTime, 'hh:mm'));
      const isEndBeforeStartDate = this.$moment(endDateTime, 'YYYY-MM-DD').isBefore(this.$moment(startDateTime, 'YYYY-MM-DD'));
      const isSameDate = startDateTime.isSame(endDateTime, 'day');


      if (isEndBeforeStartHour && isSameDate) {
        this.$openToastObject(this.$t('Ha ocurrido un error'), this.$t('El tiempo de fin no puede ser menor al de inicio'), 'danger');
        return;
      }
      if (isEndBeforeStartDate) {
        this.$openToastObject(this.$t('Ha ocurrido un error'), this.$t('La fecha de fin no puede ser menor a la de inicio'), 'danger');
        return;
      }

      this.$emit('done', this.modalHour);

      // Edit
      // if (this.newHora.id) {
      //   if (this.validTime) {
      //     if(validateTo) {
      //       console.log('VALIDATE');
      //       if(validateTo == 'from') {
      //         this.$emit('update:newHora', { from: value });
      //       } else {
      //         this.$emit('update:newHora', { to: value });
      //       }
      //     } else {
      //       console.log('NO VALIDATE');
      //       this.$emit('update:newHora', { from: value });
      //       this.$emit('update:newHora', { to: value });
      //     }
      //   }
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
  .date-modal {
    background-color: rgba(0, 0, 0, 0.4);
    --width: 290px;
    --height: 382px;
    --border-radius: 8px;
  }

  .date-modal ion-datetime {
    height: 382px;
    width: 100% !important;
  }

  .delete-box {
    display: flex;
    justify-content: center;
  }

  ion-input[field-type="type"] {
    text-align: right;
  }

</style>