export async function downloadOffline(data: any, vars: any) {

    const gmao = vars.gmao;
    const wo = vars.wo;
    const offline = vars.offline;
    let app = vars.app;

    if ('appContext' in app) app = app.appContext.config.globalProperties;
    else app = app.config.globalProperties

    const sqlite = app?.$sqlite;


    try {
        offline.showLoader = true;
        return getSchemasDB().then(async (schemas:any) => {
            schemas = schemas.map((s:any) => {
                return {
                    "Create Table": s['Create Table'].replaceAll('utf8mb4', 'utf8'),
                    "Table": s['Table']
                };
            });

            await app?.$initSQLiteDB(schemas).then(async (s:any) => {
                await s.closeConnection("gmaoTecnicos", false).then(async () => {
                    await app?.$buildSQLiteDB('usuarios', data, sqlite).then(async (s:any) => {
                        await s.closeConnection("gmaoTecnicos", false);
                    });

                    await getDirecciones().then(async (direcciones:any) => {
                        return await app?.$buildSQLiteDB('direcciones', direcciones, sqlite).then(async (s:any) => {
                            await s.closeConnection("gmaoTecnicos", false);
                        });
                    });

                    await getPartes().then(async (partes:any) => {
                        return await app?.$buildSQLiteDB('partes', partes, sqlite).then(async (s:any) => {
                            await s.closeConnection("gmaoTecnicos", false);
                        });
                    });

                    await getTecnicos().then(async (tecnicos:any) => {
                        return await app?.$buildSQLiteDB('usuarios', tecnicos, sqlite).then(async (s:any) => {
                            await s.closeConnection("gmaoTecnicos", false);
                        });
                    });

                    await getJornadasTecnico().then(async (jornadasTecnico:any) => {
                        return await app?.$buildSQLiteDB('jornada_horas', jornadasTecnico, sqlite).then(async (s:any) => {
                            await s.closeConnection("gmaoTecnicos", false);
                        });
                    });

                    await getVehicles().then(async (vehiculos:any) => {
                        return await app?.$buildSQLiteDB('vehiculos', vehiculos, sqlite).then(async (s:any) => {
                            await s.closeConnection("gmaoTecnicos", false);
                        });
                    });

                    await getHourTypes().then(async (tipos:any) => {
                        return await app?.$buildSQLiteDB('tipos_tiempo', tipos, sqlite).then(async (s:any) => {
                            await s.closeConnection("gmaoTecnicos", false);
                        });
                    });

                    await getWoTypes().then(async (tipos:any) => {
                        return await app?.$buildSQLiteDB('parte_tipos', tipos, sqlite).then(async (s:any) => {
                            await s.closeConnection("gmaoTecnicos", false);
                        });
                    });
                    await getProyectoParteTipos().then(async (tipos:any) => {
                        return await app?.$buildSQLiteDB('proyecto_parte_tipos', tipos, sqlite).then(async (s:any) => {
                            await s.closeConnection("gmaoTecnicos", false);
                        });
                    });
                    await getSolutionTexts().then(async (textos:any) => {
                        return await app?.$buildSQLiteDB('textos_predefinidos', textos, sqlite).then(async (s:any) => {
                            await s.closeConnection("gmaoTecnicos", false);
                        });
                    });
                    await getAlmacenes().then(async (almacenes:any) => {
                        return await app?.$buildSQLiteDB('almacenes', almacenes, sqlite).then(async (s:any) => {
                            await s.closeConnection("gmaoTecnicos", false);
                        });
                    });
                    await getMaquinas().then(async (maquinas:any) => {
                        return await app?.$buildSQLiteDB('maquinas', maquinas, sqlite).then(async (s:any) => {
                            await s.closeConnection("gmaoTecnicos", false);
                        });
                    });
                    await getMaterials().then(async (articulos:any) => {
                        return await app?.$buildSQLiteDB('articulos', articulos, sqlite).then(async (s:any) => {
                            await s.closeConnection("gmaoTecnicos", false);
                        });
                    });
                    await getGamas().then(async (gamas:any) => {
                        return await app?.$buildSQLiteDB('gamas', gamas, sqlite).then(async (s:any) => {
                            await s.closeConnection("gmaoTecnicos", false);
                        });
                    });
                    await getQuestions().then(async (preguntas:any) => {
                        return await app?.$buildSQLiteDB('faq_preguntas', preguntas, sqlite).then(async (s:any) => {
                            await s.closeConnection("gmaoTecnicos", false);
                        });
                    });
                    await getReplies().then(async (respuestas:any) => {
                        return await app?.$buildSQLiteDB('faq_respuestas', respuestas, sqlite).then(async (s:any) => {
                            await s.closeConnection("gmaoTecnicos", false);
                        });
                    });
                    await getDocumentosSQL().then(async (gamas:any) => {
                        return await app?.$buildSQLiteDB('documentos', gamas, sqlite).then(async (s:any) => {
                            await s.closeConnection("gmaoTecnicos", false);
                        });
                    });
                    await getModelos().then(async (maquinaModelos:any) => {
                        return await app?.$buildSQLiteDB('maquina_modelos', maquinaModelos, sqlite).then(async (s:any) => {
                            await s.closeConnection("gmaoTecnicos", false);
                        });
                    });
                    await getRepuestosMaquinaSQL().then(async (repuestos:any) => {
                        return await app?.$buildSQLiteDB('repuestos_en_maquina', repuestos, sqlite).then(async (s:any) => {
                            await s.closeConnection("gmaoTecnicos", false);
                        });
                    });
                    await getLotesMaterial().then(async (lotes:any) => {
                        return await app?.$buildSQLiteDB('lotes', lotes, sqlite).then(async (s:any) => {
                            await s.closeConnection("gmaoTecnicos", false);
                        });
                    });
                    await getAlmacenArticulos().then(async (almacenArticulos:any) => {
                        return await app?.$buildSQLiteDB('almacen_articulos', almacenArticulos, sqlite).then(async (s:any) => {
                            await s.closeConnection("gmaoTecnicos", false);
                        });
                    });
                    await getLotesAlmacenesSQL().then(async (lotes_almacenes:any) => {
                        return await app?.$buildSQLiteDB('lotes_almacenes', lotes_almacenes, sqlite).then(async (s:any) => {
                            await s.closeConnection("gmaoTecnicos", false);
                            offline.showLoader = false;
                            if (offline.counterQueue > 0) {
                                offline.counterQueue = 0;
                            }
                        });
                    });

                });
            });
        });
    } catch (error) {
        offline.showLoader = false;
        app?.$Sentry.captureException(error);
    }

    function getSchemasDB() {
        return app?.$axios
            .get('/v2/users/actions.php', {
                params: {
                    call: 'getSchemasDB',
                    token: gmao.user.token,
                    v2: 1,
                },
            })
            .then(({ data }:any) => {
                return data;
            })
            .catch((error:any) => {
                throw Error('Get Schemas', error);
            });
    }

    function getDocumentosSQL() {
        return app?.$axios
            .get('/v2/users/actions.php', {
                params: {
                    call: 'getDocumentosSQL',
                    token: gmao.user.token,
                    v2: 1,
                },
            })
            .then(({ data }:any) => {
                return data;
            })
            .catch((error:any) => {
                throw Error('Get Documentos', error);
            });
    }

    function getAlmacenArticulos() {
        return app?.$axios
            .get('/v2/users/actions.php', {
                params: {
                    call: 'getAlmacenArticulosSQL',
                    token: gmao.user.token,
                    v2: 1,
                },
            })
            .then(({ data }:any) => {
                return data;
            })
            .catch((error:any) => {
                throw Error('Get AlmacenArticulos', error);
            });
    }

    function getModelos() {
        return app?.$axios
            .get('/v2/users/actions.php', {
                params: {
                    call: 'getModelos',
                    token: gmao.user.token,
                    page: -1,
                    v2: 1,
                },
            })
            .then(({ data }:any) => {
                return data;
            }).catch((error:any) => {
                console.error(error);
            });
    }

    function getTecnicos() {
        return app?.$axios
            .get('/v2/users/actions.php', {
                params: {
                    call: 'getTecnicos',
                    token: gmao.user.token,
                    page: -1,
                    v2: 1,
                },
            })
            .then(({ data }:any) => {
                return data;
            })
            .catch((error:any) => {
                console.error(error);
            });
    }

    function getAlmacenes() {
        return app?.$axios
            .get('/v2/users/actions.php', {
                params: {
                    call: 'getAlmacenes',
                    token: gmao.user.token,
                    page: -1,
                    v2: 1,
                },
            })
            .then(({ data }:any) => {
                gmao.warehouse = data;
                return data;
            })
            .catch((error:any) => {
                console.error(error);
            });
    }

    function getPartes() {
        return app?.$axios
            .get('/v2/users/actions.php', {
                params: {
                    call: 'getPartes',
                    u: gmao.user.id,
                    token: gmao.user.token,
                    order_column: 'fecha',
                    order_cardinality: 'DESC',
                    offline_date: 1,
                    limit_maquinas: 1,
                    page: -1,
                    v2: 1,
                },
            })
            .then(({ data }:any) => {
                // wo?.workorders.push(data);

                // Cargar imagenes para offline
                // if (!+process?.env?.VUE_APP_FORZAR_LOGIN_UNICO) {
                //   wo.offlineWorkorders[0].forEach((wo) => {
                //     if (wo.imagenes) {
                //       wo.imagenes.forEach((im) => {
                //         getBase64FromUrl(im.src).then((data) => {
                //           im.base64 = data;
                //         });
                //       });
                //     }
                //   });
                // }
                return data;
            })
            .catch((error:any) => {
                console.log('getPartes: ', error);
            });
    }

    // async getBase64FromUrl(url) {
    //   const data = await fetch(url);
    //   const blob = await data.blob();
    //   return new Promise((resolve) => {
    //     const reader = new FileReader();
    //     reader.readAsDataURL(blob);
    //     reader.onloadend = () => {
    //       const base64data = reader.result;
    //       resolve(base64data);
    //     };
    //   });
    // },

    function getQuestions() {
        return app?.$axios
            .get('/v2/users/actions.php', {
                params: {
                    call: 'getPreguntas',
                    token: gmao.user.token,
                    value: gmao.user.id,
                    page: -1,
                    v2: 1,
                },
            })
            .then(({ data }:any) => {
                return data;
            })
            .catch((error:any) => {
                console.error('GetPreguntas: ', error);
            });
    }

    function getReplies() {
        return app?.$axios
            .get('/v2/users/actions.php', {
                params: {
                    call: 'getRespuestas',
                    token: gmao.user.token,
                    field: 'id_pregunta',
                    page: -1,
                    v2: 1,
                },
            })
            .then(({ data }:any) => {
                return data;
            })
            .catch((error:any) => {
                console.error('GetPreguntas: ', error);
            });
    }

    function getGamas() {
        return app?.$axios
            .get('/v2/users/actions.php', {
                params: {
                    call: 'getGamas',
                    token: gmao.user.token,
                    page: -1,
                    v2: 1,
                },
            })
            .then(({ data }:any) => {
                return data;
            })
            .catch((error:any) => {
                console.error('GetGamas: ', error);
            });
    }

    function getWoTypes() {
        return app?.$axios
            .get('/v2/users/actions.php', {
                params: {
                    call: 'getWoTypes',
                    token: gmao.user.token,
                    page: -1,
                    v2: 1,
                },
            })
            .then(({ data }:any) => {
                return data;
            })
            .catch((error:any) => {
                console.error('GetProyectoParteTipos: ', error);
            });
    }

    function getProyectoParteTipos() {
        return app?.$axios
            .get('/v2/users/actions.php', {
                params: {
                    call: 'getProyectoParteTipos',
                    token: gmao.user.token,
                    page: -1,
                    v2: 1,
                },
            })
            .then(({ data }:any) => {
                return data;
            })
            .catch((error:any) => {
                console.error('GetProyectoParteTipos: ', error);
            });
    }

    function getMaterials() {
        return app?.$axios
            .get('/v2/users/actions.php', {
                params: {
                    call: 'getMateriales',
                    token: gmao.user.token,
                    value: gmao.user.id,
                    almacen: (gmao.warehouse.map((w:any) => w.id) || []).toString() || '',
                    page: -1,
                    v2: 1,
                },
            })
            .then(({ data }:any) => {
                // * Si quitamos page:-1 esto es necesario

                // const materiales = {
                //   all: data.all.data,
                //   active: data.active.data,
                //   model: data.model.data,
                // };

                return Object.values(data).flat();
            })
            .catch((error:any) => {
                console.error('GetMaterials: ', error);
            });
    }

    function getSolutionTexts() {
        return app?.$axios
            .get('/v2/users/actions.php', {
                params: {
                    call: 'getTextosPredefinidos',
                    u: gmao.user.id,
                    token: gmao.user.token,
                    v2: 1,
                },
            })
            .then(({ data }:any) => {
                wo.textosPredefinidos = data;
                return data;
            })
            .catch((error:any) => {
                console.error('GetTextos: ', error);
            });
    }

    function getLotesMaterial() {
        return app?.$axios
            .get('/v2/users/actions.php', {
                params: {
                    call: 'getLotesMaterial',
                    token: gmao.user.token,
                    page: -1,
                    v2: 1,
                },
            })
            .then(({ data }:any) => {
                return data;
            })
            .catch((error:any) => {
                console.error('GetLotes: ', error);
            });
    }

    function getLotesAlmacenesSQL() {
        return app?.$axios
            .get('/v2/users/actions.php', {
                params: {
                    call: 'getLotesAlmacenesSQL',
                    token: gmao.user.token,
                    page: -1,
                    v2: 1,
                },
            })
            .then(({ data }:any) => {
                return data;
            })
            .catch((error:any) => {
                console.error('GetLotesAlmacenes: ', error);
            });
    }

    function getRepuestosMaquinaSQL() {
        return app?.$axios
            .get('/v2/users/actions.php', {
                params: {
                    call: 'getRepuestosMaquinaSQL',
                    u: gmao.user.id,
                    token: gmao.user.token,
                    v2: 1,
                },
            })
            .then(({ data }:any) => {
                return data;
            })
            .catch((error:any) => {
                console.error('GetRepuestos: ', error);
            });
    }

    function getHourTypes() {
        return app?.$axios
            .get('/v2/users/actions.php', {
                params: {
                    call: 'getHourTypes',
                    token: gmao.user.token,
                    page: -1,
                    v2: 1,
                },
            })
            .then(({ data }:any) => {
                wo.tiposHora = [];
                wo.tiposHora.push(data);
                return data;
            })
            .catch((error:any) => {
                console.error('GetTiposHora: ', error);
            });
    }

    function getDirecciones() {
        return app?.$axios
            .get('/v2/users/actions.php', {
                params: {
                    call: 'getDirecciones',
                    u: gmao.user.id,
                    token: gmao.user.token,
                    offline_date: 1,
                    page: -1,
                    v2: 1,
                },
            })
            .then(({ data }:any) => {
                return data;
            })
            .catch((error:any) => {
                console.error(error);
            });
    }

    function getMaquinas() {
        return app?.$axios
            .get('/v2/users/actions.php', {
                params: {
                    call: 'getMaquinas',
                    token: gmao.user.token,
                    page: -1,
                    v2: 1,
                },
            })
            .then(({ data }:any) => {
                return data;
            })
            .catch((error:any) => {
                console.error(error);
            });
    }

    function getJornadasTecnico() {
        return app?.$axios.get('/v2/users/actions.php', {
            params: {
                call: 'getAllTechWorkingDays',
                token: gmao.user.token,
                value: !gmao?.time?.length ? app?.$moment().tz('Europe/Madrid').format('HH:mm:ss') : null,
                page: -1,
                v2: 1
            }
        }).then(({ data }:any) => {
            data = data && data?.map((d:any) => {
                const start = d?.desde_formatted ? app.$moment(d?.desde_formatted).format() : app.$moment(`${d.fecha} ${d.inicio}`).tz('Europe/Madrid').format();
                const stop = d?.desde_formatted ?
                (d.hasta_formatted ? app.$moment(d?.hasta_formatted).format() : undefined) :
                (d.fecha_fin ? app.$moment(`${d.fecha_fin} ${d.fin}`).tz('Europe/Madrid').format() : undefined);
    
                const timeTemp:any = {
                    id: d?.id,
                    start,
                    stop,
                    // duracion: d.temp_duracion,
                    km: { from: null },
                    coords_start: {
                        latitude: d?.geo_inicio?.latitude || null,
                        longitude: d?.geo_inicio?.longitude || null,
                    },
                    address_coords: {}
                }

                Object.assign(timeTemp, {...d});

                delete(timeTemp.desde_formatted);
                delete(timeTemp.hasta_formatted);
                

                if (+gmao.user.modulos.modulo_vehiculos && d?.jornada_vehiculo) timeTemp.vehicle = d?.jornada_vehiculo?.id_vehiculo

                return timeTemp;
            }) || [];

            gmao.time = data;
            
            return data;
        }).catch((e:any) => {
            console.error('Error al traer jornadas: ', e);
            
        });
    }

    function getVehicles() {
        return app.$axios.get('/v2/users/actions.php', {
        params: {
            call: 'getVehiculos',
            token: gmao.user.token,
            value: gmao.user.id,
            page: -1,
            v2: 1
        }
        }).then(({ data }: any) => {
            return data;
        });
    }
}