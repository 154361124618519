<template>
  <ion-app :class="[{ 'scanner-hide': gmao.scanActive }, theme]">
    <ion-router-outlet />
  </ion-app>
</template>

<script>
// FIXME: Revisar el Plugin @capacitor/geolocation y poner los permisos en AndroidManifest.xml (lo pongo en App.vue porque no se me ocurre otro lado)

// CHUNK FILES
import('../node_modules/@capacitor/camera/dist/esm/web');
import('../node_modules/@capacitor/geolocation/dist/esm/web');
import('../node_modules/@capacitor/geolocation/dist/esm/index');
import('../node_modules/@capacitor/geolocation/dist/esm/definitions');
import('../node_modules/@ionic/pwa-elements/dist/esm/pwa-camera-modal.entry');
// ***
import('../node_modules/jeep-sqlite/dist/esm/jeep-sqlite.entry');


import(
  '../node_modules/@ionic/pwa-elements/dist/esm-es5/pwa-camera-modal.entry'
);
import(
  '../node_modules/@ionic/pwa-elements/dist/esm-es5/pwa-camera-modal-instance.entry'
);
import('../node_modules/@ionic/pwa-elements/dist/esm-es5/pwa-camera.entry');
// import smartlookClient from 'smartlook-client';

import {
  IonApp,
  IonRouterOutlet,
  toastController,
  getPlatforms,
} from '@ionic/vue';

import AppToolbar from '@/components/AppToolbar.vue';
// CHUNK FILES OFFLINE
import Item from '@/views/Item.vue';
import Workorder from '@/views/WorkOrder.vue';

import { defineComponent, getCurrentInstance } from 'vue';
import { useGmaoStore } from '@/stores/gmao';
import { registerPlugin, Capacitor } from '@capacitor/core';

// SQLite
import { useSQLite } from 'vue-sqlite-hook';
import { useOfflineStore } from '@/stores/offline';

const BackgroundGeolocation = registerPlugin('BackgroundGeolocation');

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
  },

  setup() {
    const gmao = useGmaoStore();
    const offline = useOfflineStore();
    const app = getCurrentInstance();

    const platforms = getPlatforms();
    gmao.isMobile = platforms.includes('mobile') || platforms.includes('mobileweb');

    // Init SQLite
    if( app != null) {
      app.appContext.config.globalProperties.$sqlite = useSQLite();
    }

    return {
      gmao,
      offline,
      AppToolbar,
      app,
      Item,
      Workorder,
    };
  },

  computed: {
    theme() {
      return `preset-${this.gmao.comportamientos?.color_gmao || '1'}`;
    },
  },

  async created() {
    // AXIOS INSTANCE
    this.openToastGeo();
    this.$axios.defaults.baseURL = this.gmao.workspace.api;

    this.$axios.interceptors.response.use((response) => {
      if (response.data?.error === 401) {
        this.$router.replace({ name: 'login' });
        this.openToastOptions();
      }

      return response;
    });

    // GEOLOCATION
    // https://github.com/capacitor-community/background-geolocation

    if (
      Capacitor.isPluginAvailable('BackgroundGeolocation') &&
      this.gmao.user?.id
    ) {
      BackgroundGeolocation.addWatcher(
        {
          backgroundTitle: this.$t('geolocalizacion-activada'),
          backgroundMessage: this.$t(
            'puede-que-la-aplicacion-use-la-geolocalizacion'
          ),
          requestPermissions: true,
          // distanceFilter: 20,
          distanceFilter: 50, // El valor mínimo en metros para recibir una actualización
          disableElasticity: false, // Permite ajustes basados en la velocidad del usuario
          elasticityMultiplier: 2 // Factor de multiplicación para la elasticidad
        },
        (location, error) => {
          if (error) {
            if (error.code === 'NOT_AUTHORIZED') {
              if (window.confirm(this.$t('alert-geolocalizacion'))) {
                BackgroundGeolocation.openSettings();
              }
            }
          }

          if (this.gmao.user?.id) {
            this.$axios.post(
              `/v2/users/actions.php?call=setTecnicoLocation&token=${this.gmao.user.token}&v2=1`,
              { data: {
                id_tecnico: this.gmao.user.id,
                latitude: location.latitude,
                longitude: location.longitude,
                altitude: location.altitude,
                accuracy: location.accuracy,
                speed: location.speed,
                time: location.time,
                platform: getPlatforms()[0],
              }},
              {
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded',
                },
              }
            );
          }
        }
      );
    }

    //sysadmin@gmao.cloud - Suvxas-8dysvy-zunmer
    //TODO: En web no va y en el emulador tampoco hacer ningun record
    // smartlookClient.init('8ddfaa80a7f7a15a3d8a71c24666819e564abddf');
    // smartlookClient.record;
  },

  methods: {
    async openToastOptions() {
      const toast = await toastController.create({
        header: this.$t('la-sesion-ha-caducado'),
        message: this.$t('por-favor-vuelve-a-iniciar-sesion-para-continuar'),
        position: 'top',
        color: 'danger',
        duration: '4000',
        buttons: [
          {
            text: this.$t('Cerrar'),
            role: 'cancel',
          },
        ],
      });

      await toast.present();
    },

    async openToastGeo() {
      const toast = await toastController.create({
        header: this.$t('informacion'),
        message: this.$t('puede-que-la-aplicacion-use-la-geolocalizacion'),
        position: 'bottom',
        color: 'secondary',
        duration: '4000',
        buttons: [
          {
            text: this.$t('ok'),
            role: 'cancel',
          },
        ],
      });

      await toast.present();
    },
  },
});
</script>

<style lang="scss">
ion-searchbar.defaultSearchBar {
  --background: #fff;
  --border-radius: 1rem;
}
</style>