<template>
  <ion-modal
    :is-open="modelValue"
    @didDismiss="$emit('update:modelValue', false)"
    :initialBreakpoint="0.95"
  >
    <ion-header>
      <ion-toolbar>
          <ion-buttons slot="start">
            <ion-button @click="$emit('close')">
              {{ $t('Cancelar') }}
          </ion-button>
          </ion-buttons>
        <ion-title>{{ $t('Abrir nuevo parte') }}</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding">
      <ion-item :disabled="this.offline.status" v-if="+gmao.modulos.modulo_proyectos" @click="modalProject">
        <ion-label>{{ $t('Proyecto') }}</ion-label>
        <ion-input
          class="ion-text-right"
          :value="`${workorderData.proyecto?.cod_proyecto || ''} ${workorderData.proyecto?.titulo || ''}`"
          readonly
          clear-input
        ></ion-input>
      </ion-item>

      <ion-item v-if="teams?.length" @click="chooseTeam">
        <ion-label>{{ $t('Equipo') }}</ion-label>
        <ion-input
          class="ion-text-right"
          :value="workorderData.team?.nombre"
          readonly
          clear-input
        ></ion-input>
      </ion-item>

      <ion-item v-if="!+gmao.comportamientos?.gmao_interno" @click="chooseAddress">
        <ion-label>{{ $t('Dirección') }}</ion-label>
        <ion-input
          class="ion-text-right"
          :value="workorderData.address?.nombre"
          readonly
          clear-input
        ></ion-input>
      </ion-item>

      <ion-item @click="setWoType = true" v-if="woTypes?.length">
        <ion-label>{{ $t('Subtipo') }}</ion-label>
        <ion-input
          class="ion-text-right"
          :value="workorderData.type?.nombre"
          readonly
          clear-input
        ></ion-input>
      </ion-item>

      <ion-item style="--padding-bottom:-30px;">
        <ion-label position="floating">{{ $t('Descripcion') }}</ion-label>
        <ion-textarea rows="9" v-model="workorderData.problema"></ion-textarea>
      </ion-item>

      <ion-button :disabled="disableCreate" expand="full" @click="createWorkorder(workorderData)">
        {{ $t('Crear Ot') }}
      </ion-button>
    </ion-content>
  </ion-modal>

  <ion-modal
    :is-open="setAddress"
    @didDismiss="setAddress = false"
    :initialBreakpoint="0.95"
  >
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="setAddress = false">{{ $t('Cancelar') }}</ion-button>
        </ion-buttons>

        <ion-title>{{ $t('seleccionar-direccion') }}</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding" style="height:calc(100% - 80px)">
      <ion-searchbar
        v-if="searchAddresses"
        :placeholder="$t('buscar-direccion')"
        v-model="addressSearchAjax"
        @ionInput="getSearchAddresses(true)"
        :debounce="1000"
      />

      <ion-searchbar
        v-else
        :placeholder="$t('buscar-direccion')"
        v-model="addressSearch"
      />

      <ion-list>
        <ion-item
          @click="workorderData.address = null; setAddress = false"
        >
          <ion-label>
            <h3>{{ $t('Sin direccion') }}</h3>
          </ion-label>
        </ion-item>

        <ion-item
          v-for="address in computedAddresses"
          :key="`address-${address.id}`"
          v-model="newWorkorder.address"
          @click="selectDireccion(address)"
        >
          <ion-label>
            <h3>{{ address.nombre + ' - ' + address?.cliente?.nombre }}</h3>
            <p>{{ address.direccion }}</p>
          </ion-label>
        </ion-item>
      </ion-list>
      <ion-infinite-scroll
        threshold="100px"
        id="infinite-scroll-activos"
        @ionInfinite="onLoadDirecciones($event)"
      >
        <ion-infinite-scroll-content
          loading-spinner="bubbles"
          :loading-text="$t('Cargando más direcciones...')"
        >
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>
  </ion-modal>

  <ion-modal
    :is-open="setTeam"
    @didDismiss="setTeam = false"
    :initialBreakpoint="0.95"
  >
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="setTeam = false">{{ $t('Cancelar') }}</ion-button>
        </ion-buttons>

        <ion-title>{{ $t('seleccionar-equipo') }}</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding" style="height:calc(100% - 80px)">
      <ion-searchbar
        :placeholder="$t('buscar-equipo')"
        v-model="teamSearch"
      />

      <ion-list>
        <ion-item
          @click="workorderData.team = null; setTeam = false"
        >
          <ion-label>
            <h3>{{ $t('Sin equipo') }}</h3>
          </ion-label>
        </ion-item>

        <ion-item
          v-for="team in computedTeams"
          :key="`team-${team.id}`"
          v-model="newWorkorder.team"
          @click="selectEquipo(team)"
        >
          <ion-label>
            <h3>{{ team.nombre }}</h3>
          </ion-label>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-modal>

  <ion-modal
    :is-open="setWoType"
    @didDismiss="setWoType = false"
    :initialBreakpoint="0.95"
  >
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="setWoType = false">{{ $t('Cancelar') }}</ion-button>
        </ion-buttons>

        <ion-title>{{ $t('seleccionar-subtipo') }}</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding" style="height:calc(100% - 80px)">
      <ion-searchbar
        :placeholder="$t('buscar-subtipo')"
        v-model="typeSearch"
      />

      <ion-list>
        <ion-item
          @click="workorderData.type = null; setWoType = false"
        >
          <ion-label>
            <h3>{{ $t('Sin subtipo') }}</h3>
          </ion-label>
        </ion-item>

        <ion-item
          v-for="type in computedWoSubtypes"
          :key="`type-${type.id}`"
          v-model="newWorkorder.type"
          @click="selectType(type)"
        >
          <ion-label>
            <h3>{{ type.nombre }}</h3>
          </ion-label>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-modal>

  <ion-modal
    :is-open="setProject"
    @didDismiss="setProject = false"
    :initialBreakpoint="0.95"
  >
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="setProject = false">{{ $t('Cancelar') }}</ion-button>
        </ion-buttons>

        <ion-title>{{ $t('Seleccionar proyecto') }}</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding" style="height:calc(100% - 80px)">
      <ion-searchbar
        :placeholder="$t('Buscar proyecto...')"
        v-model="projectSearch"
        @ionInput="applyFilters(true)"
        :debounce="1000"
      />

      <ion-list>
        <ion-item
          @click="workorderData.proyecto = null; setProject = false; selectProyecto(0)"
        >
          <ion-label>
            <h3>{{ $t('Sin proyecto') }}</h3>
          </ion-label>
        </ion-item>
        <ion-item
          v-for="proyecto in proyectos.flat()"
          :key="`proyecto-${proyecto.id}`"
          v-model="newWorkorder.proyecto"
          @click="selectProyecto(proyecto)"
        >
          <ion-label>
            <h3>{{ proyecto?.cod_proyecto + ' - ' + proyecto.titulo }}</h3>
            <!-- <p>{{ proyecto.direccion }}</p> -->
          </ion-label>
        </ion-item>
      </ion-list>
      <ion-infinite-scroll
        threshold="100px"
        id="infinite-scroll-activos"
        @ionInfinite="onLoad($event)"
      >
        <ion-infinite-scroll-content
          loading-spinner="bubbles"
          :loading-text="$t('Cargando más proyectos...')"
        >
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>
  </ion-modal>
</template>

<script>
import {
  IonModal,
  IonButton,
  IonSearchbar,
  IonList,
  IonToolbar,
  IonHeader,
  IonTitle,
  IonButtons,
  IonLabel,
  IonInput,
  IonItem,
  IonTextarea,
  IonContent,
  toastController,
  loadingController,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from '@ionic/vue';
import { informationCircle } from 'ionicons/icons';

import { ref, getCurrentInstance } from 'vue';
import { useGmaoStore } from '@/stores/gmao';
import { useWorkOrdersStore } from '@/stores/workorders';

// SQLite
import { SQLiteDBConnection } from 'vue-sqlite-hook/dist';

import { useOfflineStore } from '@/stores/offline';

export default {
  name: 'NewWorkorder',
  props: {
    modelValue: {
      required: true,
      type: Boolean,
    },

    incidence: {
      type: Object,
    },

    addresses: {
      type: Object,
    },

    customAddress: {
      type: Object,
    },

    customFacility: {
      type: Object,
    },

    anomaliesAssets: {
      type: Array,
    },

    searchAddresses: {
      type: Boolean,
    }
  },

  emits: [
    'done',
  ],

  components: {
    IonModal,
    IonButton,
    IonSearchbar,
    IonList,
    IonToolbar,
    IonHeader,
    IonTitle,
    IonButtons,
    IonLabel,
    IonInput,
    IonItem,
    IonTextarea,
    IonContent,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
  },

  computed: {
    computedAddresses() {
      return (this.searchedAddresses.flat())?.filter((a) =>
        Object.values({
            direccion: a?.direccion,
            nombre: a?.nombre,
            cp: a?.cp,
            cliente: a.cliente?.nombre,
            })
          .join(';')
          ?.toLowerCase()
          .includes((this.addressSearch || '').toLowerCase())
      );
    },

    computedTeams() {
      return this.teams?.filter((a) =>
        (a?.nombre || '')?.toLowerCase()
          .includes((this.teamSearch || '').toLowerCase())
      );
    },

    computedWoSubtypes() {
      return this.woTypes?.filter((a) =>
        (a?.nombre || '')?.toLowerCase()
          .includes((this.typeSearch || '').toLowerCase())
      );
    }
  },

  setup() {
    const gmao = useGmaoStore();
    const wo = useWorkOrdersStore();
    const offline = useOfflineStore();

    const app = getCurrentInstance();

    return {
      offline,
      app,
      gmao,
      wo,
      informationCircle,
      setAddress: ref(false),
      setTeam: ref(false),
      setProject: ref(false),
      disableCreate: ref(false),
      addressSearch: ref(null),
      addressSearchAjax: ref(null),
      teamSearch: ref(null),
      projectSearch: ref(null),
      newWorkorder: ref({}),
      workorderData: ref({}),
      proyectos: ref([]),
      searchedAddresses: ref([]),
      typeSearch: ref(''),
      woTypes: ref([]),
      setWoType: ref(false),
      teams: ref([]),

      current_page: ref(1),
      last_page: ref(null),
      loading: ref(false),
      total: ref(null),
    };
  },

  created(){
    this.getDirecciones();
  },

  watch: {
    'modelValue': {
      handler(val) {
        if (val) {
          if (
            this.customAddress?.id ||
            ((this.addresses?.length == 1 || this.searchedAddresses?.flat()?.length == 1)
            && +this.gmao.user.comportamientos?.direccion_preestablecida_autosignacion)
          ) {
            this.workorderData.address = this.customAddress || this.addresses[0] || this.searchedAddresses.flat()[0];
          }

          this.getWoTypes();
        }
      },
      deep: true
    },

    'proyectos': {
      handler(val) {
        if (val?.length == 1) {
          this.workorderData.proyecto = val[0];
        }
      }
    },

    'addresses': {
      handler(val) {
        if (val.length == 1) this.workorderData.address = val[0];
      }
    },

    'teams': {
      handler(val) {
        if (val.length == 1) this.workorderData.team = val[0];
      }
    },

    setAddress(v) {
      if (!+this.gmao.comportamientos.ver_direcciones_en_proyecto) {
        if (v) this.applyFiltersDirecciones(true);
      }
    }
  },

  methods: {
    selectProyecto(proyecto) {
      this.workorderData.proyecto = proyecto;
      this.setProject = false;
      this.getWoTypes();
      this.getTeams(this.workorderData?.proyecto?.id);

      this.workorderData.type = null;
      if(+this.gmao.comportamientos.ver_direcciones_en_proyecto) {
        this.applyFiltersDirecciones(true, this.workorderData.proyecto);
      } else this.$emit('proyecto', proyecto.id);
    },

    getTeams(proyecto) {
      this.$axios
        .get('/v2/users/actions.php', {
          params: {
            call: 'getEquipos',
            u: this.gmao.user.id,
            token: this.gmao.user.token,
            proyecto: proyecto || '',
            v2: 1,
            page: -1
          },
        })
        .then(({ data }) => {
          this.teams = data;
        });
    },

    getWoTypes() {
      console.log('A');
      return this.$axios
        .get('/v2/users/actions.php', {
          params: {
            call: 'getWoTypes',
            u: this.gmao.user.id,
            token: this.gmao.user.token,
            proyecto: this.workorderData?.proyecto?.id || '',
            v2: 1,
          },
        }).then(({ data }) => {
          return this.woTypes = data;
        }).catch(async (e) => {
          if (e.code == 'ERR_NETWORK' && this.offline.status) {
              try {
                const sqlite = this.app?.appContext.config.globalProperties.$sqlite;

                // if (this.settingPartes) {
                //   await sqlite.closeConnection('gmaoTecnicos', false);
                // }

                const ret = await sqlite.checkConnectionsConsistency();
                const isConn = (await sqlite.isConnection('gmaoTecnicos', false)).result;

                console.log(`WOS: Connection Consistency ${ret.result} and Connection ${isConn}`);

                let db = new SQLiteDBConnection();
                if (ret.result && isConn) {
                  db = await sqlite.retrieveConnection('gmaoTecnicos', false);
                } else {
                  db = await sqlite.createConnection(
                    'gmaoTecnicos',
                    false,
                    'no-encryption',
                    1,
                    false
                  );
                }
                await db.open();
                const tiposPromise = new Promise((resolve) => {
                  const tiposParte = db.query(`
                    SELECT *
                    FROM parte_tipos
                    ${this.workorderData?.proyecto?.id ? `
                    WHERE id IN (
                      SELECT id_parte_tipo
                      FROM proyecto_parte_tipos
                      WHERE id_proyecto=${this.workorderData?.proyecto?.id}
                    );
                  )`: ''}`);
                  resolve(tiposParte);
                });

                tiposPromise.then((value) => {
                  const valores = value.values;
                  if (!valores?.length) {
                    const todosTiposPromise = new Promise((resolve) => {
                      const tiposParte = db.query(`SELECT * FROM parte_tipos;`);
                      resolve(tiposParte);
                    });
                    todosTiposPromise.then((value) => {
                      setTimeout(() => {
                        this.woTypes = value.values;
                        sqlite.closeConnection('gmaoTecnicos');
                      }, 500);
                    });
                  } else  {
                    setTimeout(() => {
                      this.woTypes = valores;
                      sqlite.closeConnection('gmaoTecnicos');
                    }, 500);
                  }
                });
              } catch (err) {
                this.$Sentry.captureException(err);
              }
            }
        })
    },

    modalProject() {
      this.setProject = true;
      this.applyFilters(true);
    },

    getSearchAddresses(reset = true) {
      if(reset) this.searchedAddresses = [];
      // this.getDirecciones(this.addressSearchAjax);
      this.applyFiltersDirecciones(true);
    },

    onLoadDirecciones(event) {
      if (!this.loading && this.current_page < this.last_page) {
        this.current_page += 1;
        this.applyFiltersDirecciones(false).then(() => {
          event.target.complete();
        });
      } else event.target.complete();
    },

    applyFiltersDirecciones(reset = true,proyecto = null) {
      if (reset) {
        this.searchedAddresses = [];
        this.current_page = 1;
      }
      return this.getDirecciones(this.addressSearchAjax,proyecto);
    },

    async getDirecciones(search = null,proyecto = null) {
      return await this.$axios
        .get('/v2/users/actions.php', {
          params: {
            call: 'getDirecciones',
            u: this.gmao.user.id,
            token: this.gmao.user.token,
            page: this.current_page,
            v2: 1,
            proyecto: proyecto ? proyecto.id : null,
            search
          },
        })
        .then(({ data }) => {
          this.searchedAddresses = [];
          this.searchedAddresses.push(data.data);
          this.current_page = data.current_page;
          this.last_page = data.last_page;
          this.total = data.total;
          if ((this.addresses?.length == 1 || this.searchedAddresses?.flat()?.length == 1) && +this.gmao.user.comportamientos?.direccion_preestablecida_autosignacion) {
            this.workorderData.address = this.addresses?.[0] || this.searchedAddresses.flat()[0];
          } else {
            this.workorderData.address = null;
          }
        }).catch(async (e) => {
            if (e.code == 'ERR_NETWORK' && this.offline.status) {

              try {
                const sqlite = this.app?.appContext.config.globalProperties.$sqlite;

                const ret = await sqlite.checkConnectionsConsistency();
                const isConn = (await sqlite.isConnection('gmaoTecnicos', false)).result;

                console.log(`WOS: Connection Consistency ${ret.result} and Connection ${isConn}`);
                let db = new SQLiteDBConnection();
                if (ret.result && isConn) {
                  db = await sqlite.retrieveConnection('gmaoTecnicos', false);
                } else {
                  db = await sqlite.createConnection(
                    'gmaoTecnicos',
                    false,
                    'no-encryption',
                    1,
                    false
                  );
                }
                await db.open();

                const query = `
                  SELECT *
                  FROM direcciones
                  ${search?.length && search ? `WHERE nombre LIKE "%${search}%" OR direccion LIKE "%${search}%"`: ''}
                  `;

                const getDireccionesPromise = new Promise((resolve) => {
                    resolve(this.$SQLiteQuery('getDirecciones_new_workorder', 'direcciones', query, e.config, db));
                });
                await getDireccionesPromise.then(() => {
                  this.searchedAddresses = [];
                  setTimeout(() => {
                    this.searchedAddresses = [this.offline.addresses];
                    sqlite.closeConnection('gmaoTecnicos');
                  }, 500);
                });
              } catch (err) {
                this.$Sentry.captureException(err);
              }
            }
          });
    },

    selectDireccion(address) {
      this.workorderData.address = address;
      this.setAddress = false;
      if (!this.workorderData.proyecto) {

        if (+this.gmao.modulos.modulo_proyectos) {
          this.setProject = true;
          this.workorderData.proyecto = null;
        }

        this.applyFilters(true, true);

      } else this.applyFilters(true);
    },

    selectEquipo(team) {
      this.workorderData.team = team;
      this.setTeam = false;
    },

    selectType(type) {
      this.workorderData.type = type;
      this.setWoType = false;
    },

    chooseAddress() {
      if (!((this.addresses?.length == 1 || this.searchedAddresses?.flat()?.length == 1) && +this.gmao.user.comportamientos?.direccion_preestablecida_autosignacion)) {
        this.setAddress = true;
      }
    },

    chooseTeam() {
      if (this.teams.length != 1) {
        this.setTeam = true;
      }
    },

    onLoad(event, statusCall = false) {
      if (!this.loading && this.current_page < this.last_page) {
        this.current_page += 1;
        this.applyFilters(false, statusCall).then(() => {
          event.target.complete();
        });
      } else {
        // event.target.disabled = true;
        event.target.complete();
      }
    },

    applyFilters(reset = true, statusCall = false) {
      if (reset) {
        this.proyectos = [];
        this.current_page = 1;
      }
      return this.getProyectos(statusCall);
    },

    getProyectos(withProject = false) {
      return this.$axios
        .get('/v2/users/actions.php', {
          params: {
            call: 'getProyectos',
            u: this.gmao.user.id,
            token: this.gmao.user.token,
            direccion: withProject && this.workorderData?.address?.id || null,
            cliente: this.workorderData?.address?.cliente?.id || null,
            search: this.projectSearch,
            page: this.current_page,
            v2: 1,
          },
        }).then(({ data }) => {
          if (data?.data?.length) {
            this.proyectos.push(data.data);

            this.current_page = data.current_page;
            this.last_page = data.last_page;
            this.total = data.total;
          }
        }).catch(async (e) => {
            if (e.code == 'ERR_NETWORK' && this.offline.status) {

              // TODO: Hay que añadir las llamadas de proyectos y sus relaciones...
              // try {
              //   const sqlite = this.app?.appContext.config.globalProperties.$sqlite;

              //   const ret = await sqlite.checkConnectionsConsistency();
              //   const isConn = (await sqlite.isConnection('gmaoTecnicos', false)).result;

              //   console.log(`WOS: Connection Consistency ${ret.result} and Connection ${isConn}`);
              //   let db = new SQLiteDBConnection();
              //   if (ret.result && isConn) {
              //     db = await sqlite.retrieveConnection('gmaoTecnicos', false);
              //   } else {
              //     db = await sqlite.createConnection(
              //       'gmaoTecnicos',
              //       false,
              //       'no-encryption',
              //       1,
              //       false
              //     );
              //   }
              //   await db.open();

              //   const query = `
              //     SELECT *
              //     FROM proyectos
              //     ${withProject ?
              //         `INNER JOIN direcciones ON direcciones.id=${this.workorderData?.address?.id}` :
              //         `INNER JOIN tecnicos ON usuarios.id=${this.gmao?.user?.id}`
              //     }
              //     ${this.projectSearch?.length && this.projectSearch ?
              //       `WHERE titulo LIKE "%${this.projectSearch}%" OR cod_proyecto LIKE "%${this.projectSearch}%"`: ''}
              //     `;

              //   const getProyectosPromise = new Promise((resolve) => {
              //       resolve(this.$SQLiteQuery('getProyectos_new_workorder', 'proyectos', query, e.config, db));
              //   });
              //   await getProyectosPromise.then(() => {
              //     this.proyectos = [];
              //     setTimeout(() => {
              //       this.proyectos = [this.offline.proyectos];
              //       sqlite.closeConnection('gmaoTecnicos');
              //     }, 500);
              //   });
              // } catch (err) {
              //   this.$Sentry.captureException(err);
              // }
            }
          });
    },

    async createWorkorder(woData) {
      let direccion = woData.address?.id || this.customAddress?.id;
      if (woData?.proyecto?.direcciones?.length && !direccion) {
        direccion = woData.proyecto?.direcciones[0] || null;
      }
      const loading = await loadingController.create({
        message: this.$t('creando-parte'),
      });

      loading.present();
      // TODO: offline, con incidencia
        this.$axios
          .post(
            `/v2/users/actions.php?call=setParte&token=${this.gmao.user.token}&v2=1`,
            {
              data: {
                id_incidencia: this.incidence?.id || null,
                id_tecnico: this.gmao.user.id,
                id_direccion: direccion,
                id_sistema: this.customFacility?.id,
                id_proyecto: woData.proyecto?.id || null,
                id_equipo: woData?.team?.id || null,
                id_tipo: woData?.type?.id || null,
                problema: woData.problema,
                fecha: this.$moment(),
                maquinas_anomalias: (this.anomaliesAssets || [])?.map((m) => {
                    return {
                        id: m.id,
                        modelo_parte_anomalia: m?.anomalia?.id
                    };
                }) || null,
              }
            },
            {
              headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            }
          )
          .then(({ data }) => {
            this.createOfflineWorkorder(data.parte)
            this.$emit('done', data);
            this.workorderData = {};
          }).finally(() => {
            loading?.dismiss();
          }).catch(async (e) => {
            if (e.code == 'ERR_NETWORK' && this.offline.status) {
              this.openToastOptions(
                this.$t('no-tiene-conexión-a-internet'),
                this.$t('sus-datos-han-sido-guardados')
              );
              try {
                const sqlite = this.app?.appContext.config.globalProperties.$sqlite;

                const ret = await sqlite.checkConnectionsConsistency();
                const isConn = (await sqlite.isConnection('gmaoTecnicos', false)).result;

                console.log(`NEW WO: Connection Consistency ${ret.result} and Connection ${isConn}`);

                let db = new SQLiteDBConnection();
                if (ret.result && isConn) {
                  db = await sqlite.retrieveConnection('gmaoTecnicos', false);
                } else {
                  db = await sqlite.createConnection(
                    'gmaoTecnicos',
                    false,
                    'no-encryption',
                    1,
                    false
                  );
                }
                await db.open();
                if (Object.entries(woData).length) {
                  const values = {
                    id: this.$moment().unix(),
                    offline: '#FF0000',
                    id_tecnico: this.gmao.user?.id || 0,
                    id_estado_actual: 3,
                    id_direccion: woData.address?.id || this.customAddress?.id || 0,
                    id_proyecto: woData.proyecto?.id || 0,
                    id_tipo: woData.type?.id || 0,
                    problema: woData.problema,
                    fecha: this.$moment().format("YYYY-MM-DD"),
                    created_at: this.$moment().format("YYYY-MM-DD H:m:s"),
                    last_modified: this.$moment().format("YYYY-MM-DD H:m:s")
                  }
                  const syncObject = {
                    callFunction: 'setParte',
                    data: {
                      id_tecnico: this.gmao.user.id,
                      id_direccion: woData.address?.id || this.customAddress?.id,
                      id_proyecto: woData.proyecto?.id || null,
                      problema: woData.problema,
                      fecha: this.$moment(),
                    }
                  };
                  const query = `
                    INSERT INTO partes (id, syncObject, id_estado_actual, offline, id_tecnico, id_direccion, id_proyecto, id_tipo, problema, fecha, created_at, last_modified)
                    VALUES (
                      ${values.id},
                      '${JSON.stringify(syncObject)}',
                      ${values.id_estado_actual},
                      '${values.offline}',
                      ${values.id_tecnico},
                      ${values.id_direccion},
                      ${values.id_proyecto},
                      ${values.id_tipo},
                      '${values.problema || ''}',
                      '${values.fecha}',
                      '${values.created_at}',
                      '${values.last_modified}'
                    );
                    SELECT * FROM partes WHERE id=last_insert_rowid();
                  `;
                  const setNewWorkorder = new Promise((resolve) => {
                    resolve(this.$SQLiteQuery('createWorkorder_new_workorder', 'partes', query, e.config, db));
                  });
                  setNewWorkorder.then(() => {
                    setTimeout(() => {
                      sqlite.closeConnection('gmaoTecnicos').then(() => {
                        this.$emit('done', this.offline.workorder);
                        this.workorderData = {};
                      });
                    }, 500);
                  });
                } else {
                  throw Error('Algo no ha ido bien... NewWO');
                }

              } catch (error) {
                this.$Sentry.captureException(error);
                throw Error('NEW WO\'s: ', error);
              }
            }
          });
    },
    async openToastOptions(header, message, color = 'danger') {
      const toast = await toastController.create({
        header,
        message,
        icon: informationCircle,
        position: 'top',
        color,
        duration: '4000',
        buttons: [
          {
            text: this.$t('cerrar'),
            role: 'cancel',
          },
        ],
      });

      await toast.present();
    },

    async createOfflineWorkorder(woData) {
      try {
        const sqlite = this.app?.appContext.config.globalProperties.$sqlite;

        const ret = await sqlite.checkConnectionsConsistency();
        const isConn = (await sqlite.isConnection('gmaoTecnicos', false)).result;

        console.log(`NEW WO: Connection Consistency ${ret.result} and Connection ${isConn}`);

        let db = new SQLiteDBConnection();
        if (ret.result && isConn) {
          db = await sqlite.retrieveConnection('gmaoTecnicos', false);
        } else {
          db = await sqlite.createConnection(
            'gmaoTecnicos',
            false,
            'no-encryption',
            1,
            false
          );
        }
        await db.open();
        if (Object.entries(woData).length) {
          const values = {
            id: woData.id,
            id_estado_actual: woData.id_estado_actual,
            estado_actual: woData.estado_actual,
            id_tecnico: woData.id_tecnico,
            id_direccion: woData.id_direccion,
            id_proyecto: woData.id_proyecto,
            id_tipo: woData.id_tipo,
            problema: woData.problema,
            fecha: woData.fecha,
            created_at: woData.created_at,
            updated_at: woData.updated_at,
          }
          const query = `
            INSERT INTO partes (id, id_estado_actual, estado_actual, id_tecnico, id_direccion, id_proyecto, id_tipo, problema, fecha, created_at, updated_at)
            VALUES (${values.id},
              ${values.id_estado_actual},
              '${JSON.stringify(values.estado_actual)}',
              ${values.id_tecnico},
              ${values.id_direccion},
              ${values.id_proyecto},
              ${values.id_tipo},
              '${values.problema || ''}',
              '${values.fecha}',
              '${values.created_at}',
              '${values.updated_at}'
            );
            SELECT * FROM partes WHERE id=last_insert_rowid();
          `;
          const setNewWorkorder = new Promise((resolve) => {
            resolve(this.$SQLiteQuery('createWorkorder_new_workorder', 'partes', query, {method: 'post'}, db));
          });
          setNewWorkorder.then(() => {
            setTimeout(() => {
              sqlite.closeConnection('gmaoTecnicos').then(() => {
                this.$emit('done', this.offline.workorder);
              });
            }, 500);
          });
        } else {
          throw Error('Algo no ha ido bien... NewWO');
        }

      } catch (error) {
        this.$Sentry.captureException(error);
        throw Error('NEW WO\'s: ', error);
      }
    }
  },
};
</script>